@media only screen and (min-width: 320px) and (max-width: 480px) {
  .SelectTuroPadding {
    background: #f3f9ff;
    padding: 60px 45px !important;
    height: 100% !important;
  }
}
.SelectTuroPadding {
  padding: 60px 6% 60px 6%;
  /* height: 100%; */
}
.fintTutorsPadding {
  padding: 0px 10px;
}
.tutorsInfo p {
  margin-bottom: 0px;
  font-size: 13px;
}
.findTutorsMarginSapce {
  padding: 10px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 14px hsl(0deg 0% 75% / 24%);
}
.selecttuotsTime {
  cursor: pointer;
}
.selectTimeBorder .MuiDialog-paperScrollPaper {
  border: 2px solid blue !important;
}
.avaliblityFlex {
  display: flex;
  flex-wrap: wrap;
}
.imageCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.buttonCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.noDataMeesage {
  box-shadow: 0 0 14px hsl(0deg 0% 75% / 24%);
  background-color: white;
  text-align: center;
  padding: 20px;
}
.noDataMeesage img {
  width: 250px;
  height: 250px;
}
