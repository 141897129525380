@media only screen and (max-width: 600px) {
  .cardFlexCenter {
    display: block !important;
    justify-content: center;
  }
  .cardFlexCenterForSt {
    display: none !important;
    justify-content: center;
  }
  .cardFlexCenterMobile {
    display: block !important;
  }
  .arrowCardForStudent {
    min-height: 30vh !important;
  }
  .howItWorkMainDiv {
    min-height: 90vh;
    width: 100%;
    background: #f3f9ff;
    padding: 24% 6% 6% 6% !important;
  }
  .cardWidth {
    width: 100% !important;
  }
}
.howItWorkMainDiv {
  min-height: 90vh;
  width: 100%;
  background: #f3f9ff;
  padding: 7% 6% 6% 6%;
}
.howItWorkHeading {
  padding: 1% 0%;
  text-align: center;
}
.howitpaddingBoth {
  box-shadow: 0 0 14px hsl(0deg 0% 75% / 24%);
  background-color: #0070bb;
  padding: 20px;
  border-radius: 10px;
}
.howitpaddingBothForStudent {
  box-shadow: 0 0 14px hsl(0deg 0% 75% / 24%);
  background-color: #0070bb;
  padding: 20px;
  border-radius: 10px;
}
.arrowCard {
  box-shadow: 0 0 14px hsl(0deg 0% 75% / 24%);
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  min-height: 72vh;
}
.arrowCardForStudent {
  box-shadow: 0 0 14px hsl(0deg 0% 75% / 24%);
  padding: 10px;
  border-radius: 10px;
  min-height: 35vh;
  border: 2px solid #0070bb;
  background-color: white;
}
.paracaedMargin {
  margin-top: 20px;
}
.paracaedMargin p {
  border: 2px solid #0070bb;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 0px;
}
.cardWidth {
  width: 33%;
}
.arrrowFixCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrrowFixCenter .css-i4bv87-MuiSvgIcon-root {
  width: 2em;
  height: 3em;
  color: white;
}
.arrrowFixCenterForStudent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrrowFixCenterForStudent .css-i4bv87-MuiSvgIcon-root {
  width: 2em;
  height: 3em;
  color: white;
}
.bottomArrow {
  text-align: center;
}
.bottomArrow .css-i4bv87-MuiSvgIcon-root {
  color: #0070bb;
}
.Cardcenter {
  display: flex;
  align-items: center;
  height: 80%;
  justify-content: center;
}
.headingCenter {
  text-align: center;
  font-weight: bold;
}
.cardWidthforStudnet {
  width: 25%;
}
.cardFlexCenter {
  display: flex;
  justify-content: center;
}
.cardFlexCenterMobile {
  display: none;
}
.cardFlexCenterForSt {
  display: flex;
  justify-content: center;
}
.findBuutonCenterHowItWokr {
  text-align: center;
  padding-top: 2%;
}
