.privacy_mian_div {
  min-height: 90vh;
  padding-top: 80px;
  width: 100%;
}
.privacy_padding_both {
  padding: 0px 7%;
  margin: 3% 0%;
}
.privacy_card_shadow {
  background: #ffffff !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15) !important;
  border-radius: 3px !important;
}
.privacy_policy_header h2 {
  text-align: center;
}
.privacy_policy_header p {
  font-size: 14px;
  font-weight: 500;
}
.terms_of_heading_data {
  padding-top: 2%;
}
.terms_of_heading_data h3 {
  color: #0070bb;
}
.terms_of_heading_data p {
    margin-bottom: 0px !important;
}
.terms_of_heading_data ul li {
  padding-left: 10px;
}
.terms_of_heading_data ol li {
  padding-left: 10px;
}
