@media only screen and (max-width: 900px) {
  .header_flex_grow {
    display: none !important;
  }
  .mobileMenudivShow {
    display: block !important;
  }
  .home_footer_flex {
    display: block !important;
  }
  .footer_icon {
    width: 25% !important;
  }
}

.home_header_background {
  background: #ffffff !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 16px 32px -8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 3px !important;
}
.header_logo_color {
  margin: 1px;
  width: 100%;
  height: 80px !important;
}
.header_flex_grow {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header_flex_grow p {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  padding: 0% 3%;
  margin-bottom: 0px !important;
}
.header_flex_grow a {
  color: #000000 !important;
  outline: none !important;
  text-decoration: none !important;
}
.header_home_flex_content {
  flex-grow: 1;
}
.home_header_logo_padding {
  padding-top: 10px;
}
.home_footer_main_div {
  background: #1f55e1 !important;
  padding: 1% 5%;
  color: #ffffff;
}
.home_footer_flex {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.home_footer_flex_grow {
  flex-grow: 1;
}
.home_footer_font_size {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 0px !important;
}
.home_footer_copy_right {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.footer_icon_color {
  font-family: Font Awesome 5 Brands;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: #ffffff;
}
.footer_icon {
  width: 10%;
  display: flex;
  justify-content: space-around;
}
.buttonTopSetting {
  margin: -10px 3% 0% 3%;
}
.mobileMenudivShow {
  display: none;
}
.menuBarColor {
  color: #333;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1120;
  top: 0;
  left: 0;
  background-color: white;
  border-right: 1px solid lightgray;
  overflow-x: hidden;
  transition: 0.5s;
}
.cross_icon_style {
  text-align: right !important;
  color: black;
  margin-right: 5px;
  font-size: 20px;
  cursor: pointer;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: block;
  transition: 0.3s;
  text-align: left !important;
}
.buttonsetInSidebar {
  padding: 8px 8px 8px 32px;
}
.sidenav p {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: block;
  transition: 0.3s;
  margin-bottom: 0px;
  text-align: left !important;
}
