.continueButtonset {
  font-size: 14px;
  padding: 10px 30px;
  letter-spacing: 1px;
  font-weight: 400;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none !important;
  outline: none !important;
  display: inline-block;
  background: #e41d55;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  /* box-shadow: 4px 4px 32px -4px rgba(31, 85, 225, 0.45) rgb(228,29,84); */
  border-radius: 46px;
}
.countryDropDownborder {
  width: 100% !important;
  height: 45px;
  border-radius: 10px;
}
.numberInputPadding {
  padding-top: 10px;
}
.phoneInputheight {
  height: 45px;
}
.phoneInputheight.react-tel-input .form-control {
  width: 100% !important;
  height: 45px;
}
.titleCenter {
  text-align: center;
}
.iconsLeftSet {
  cursor: pointer;
  float: left;
}
.paraFontset {
  font-size: 13px;
}
.inputForm {
  width: 100%;
  height: 45px;
  border-color: #333;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: #fff;
  padding-left: 10px;
  outline: none !important;
  text-decoration: none !important;
}
.inputFormlastName {
  width: 100%;
  height: 45px;
  border-color: #333;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 10px;
  outline: none !important;
  text-decoration: none !important;
}
.inputFormWidth {
  width: 100%;
  height: 45px;
  border-color: #333;
  border-radius: 10px;
  padding-left: 10px;
}
.overSrcollpostion {
  overflow: auto;
}
.btnWidth {
  width: 100%;
}
.otpTextFlex {
  display: flex;
  justify-content: center;
}
.otpInputWidth {
  width: 10%;
  margin: 0px 7px;
  height: 45px;
  border-radius: 10px;
  outline: none !important;
  text-decoration: none !important;
  border-color: #333;
  text-align: center;
}
.optionPadding {
  padding-top: 20px;
}
.blank_error {
  color: red;
  font-size: 13px;
}
.PrivacyPolicyColor {
  color: #1f55e1;
  cursor: pointer;
}

/* .EmailPadding {
  padding-top: 20px;
} */
.EmailPadding {
  padding: 10px 10px 0px 10px;
}
.textCenter {
  text-align: center;
}
.recaptchaPadding {
  padding: 20px 0px;
}
.contentCenter {
  text-align: center;
  padding: 20px;
}
.iconColorPosition {
  height: 100px;
  width: 100px;
  background-color: #6fd649;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
}
.iconFontSize {
  font-size: 7rem !important;
  color: #6fd649;
  margin-top: -100px;
  border-radius: 50%;
  background-color: #fff !important;
}
.dialogPosition {
  position: fixed;
  top: 40%;
  border: none !important;
  background-color: #fff !important;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: 0.3s;
}
.dailogWidthHeight {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9;
}
.buttonColorWidth {
  text-decoration: none !important;
  outline: none !important;
  background-color: #6fd649;
  color: #fff;
  border: none !important;
  width: 50%;
  border-radius: 5px;
  height: 35px;
}
.iconFontSizeClose {
  font-size: 7rem !important;
  color: red;
  margin-top: -100px;
  border-radius: 50%;
  background-color: #fff !important;
}
.buttonColorWidthError {
  text-decoration: none !important;
  outline: none !important;
  background-color: red;
  color: #fff;
  border: none !important;
  width: 50%;
  border-radius: 5px;
  height: 35px;
}
.paddingAccountTeacher {
  text-align: center !important;
  padding-top: 70px !important;
  width: 600px;
  margin: auto;
}
.cardBgColor {
  background-color: white;
  padding: 10px;
}
.titleCenterColor {
  color: black;
  font-weight: 700;
}
