.best_school_heading {
  text-align: center;
}
.Faq_question {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-right: 10%;
  margin-left: 10%;
}
.faqheading {
  font-weight: bold !important;
}
.feature_count_image {
  width: 52px;
  height: 34.67px;
}
.features_main_div_padding {
  padding: 0px 70px;
}
.about_us_paragraph {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(20, 20, 49, 0.65);
}
/* ------------new css------- */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .sechedule_meeting_main_div {
    background: #0070bb;
    padding: 60px 45px !important;
    height: 100% !important;
  }
  .featuresMainDivPadding {
    padding: 30px 0px 0px 0px !important;
  }
  .Faq_question {
    padding-left: 0% !important;
    padding-right: 0% !important;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-right: 0% !important;
    margin-left: 0% !important;
  }
}
.website_home_main_div {
  min-height: 90vh;
  padding-top: 70px;
  width: 100%;
}
.sechedule_meeting_main_div {
  background: #0070bb;
  padding: 60px 6% 60px 6%;
  height: calc(100vh - 152px);
}
.shcedlue_meeting_padding_both {
  padding: 0px 10px;
}
.padding_left {
  padding-left: 100px;
}
.schedule_meeting_digital h1 {
  /* font-family: Nunito; 
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 52px;
  color: #000000;
  margin-block-end: 0px !important;
  margin-block-start: 0px !important; */
  /* font-family: Nunito; */
  /* font-style: normal; */
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  color: #000000;
  margin-block-end: 0px !important;
  margin-block-start: 0px !important;
  padding-bottom: 14px;
}
.schedule_meeting_digital span {
  color: #1f55e1;
  text-shadow: 4px 4px 62px rgba(31, 85, 225, 0.4);
}
.schedule_meeting_digital p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2c2e2f;
  font-weight: bold;
  margin-bottom: 0px;
}
.schedule_meeting_digital ul {
  list-style: none;
  padding-left: 4px;
}
.schedule_meeting_digital ul li {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2c2e2f;
  font-weight: bold;
}
.schedule_mobile_logo {
  width: 100%;
  height: 57vh;
}
.schdeule_right_grid_width {
  background: #ffffff;
  border: 1px solid rgba(176, 176, 176, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15);
  border-radius: 3px;
  padding: 20px;
  display: block;
  margin: auto;
  width: 335px;
  height: 395px;
}
.schedule_padding_filed {
  padding-top: 13px;
}
.schedule_button_fix {
  text-align: center;
  padding-top: 35px;
}
.howItWorkedFont {
  font-size: 14px;
  padding-top: 7px;
  padding-left: 10px;
}
/* --bring School css----- */
.Bring_school_main_div {
  padding: 50px 5%;
}
.Bring_school_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.Bring_school_padding_top {
  padding-top: 15%;
}
.Bring_level_color {
  color: #1f55e1;
  text-shadow: 4px 4px 62px rgba(31, 85, 225, 0.4);
}
.Our_customer_div_padding {
  padding: 27px 5%;
  background: #f3f9ff;
}
.best_school_main_div {
  padding: 10px 5% 5% 5%;
}
.bestshool_hide_data {
  background: #ffffff !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15) !important;
  border-radius: 3px !important;
}
.best_school_shadow {
  box-shadow: none !important;
  background-color: unset !important;
}
.bestshool_show_data {
  font-family: Nunito !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  color: #171742 !important;
}
.bestcshool_heading {
  font-family: Nunito !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  color: #171742 !important;
  opacity: 0.5 !important;
}
.best_school_para {
  font-family: Nunito !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #141431 !important;
}
.blank_school {
  margin-top: 10px;
}
.feature_anylises_background {
  background: #f3f9ff;
}
.feature_cloud_plateform {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 33px;
  color: #171742;
}
.feature_para_font {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #2c2e2f;
}
.about_us_div {
  display: flex;
}
.about_us_icon {
  padding-top: 5px;
}
.Faq_heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #000000;
}
.faq_para_color {
  font-family: Nunito !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #555555 !important;
}
.Our_customer_center {
  text-align: center;
}
.about_us_heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #000000;
}
.about_right_data {
  padding-left: 10px;
}
.about_right_data h3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #171742;
}
.about_right_data p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(20, 20, 49, 0.65);
}
.about_us_div_padding {
  padding: 35px 70px;
}
.learning_and_assignment {
  background: #ffffff !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15) !important;
  border-radius: 3px !important;
}
.faq_accordion_margin {
  margin-top: 15px;
}
.Contact_us_main_div {
  padding: 0px 45px 65px 45px;
}
.contact_grid_padding {
  padding-top: 20px;
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex !important;
}
.pricing_white_card_shadow {
  background: #ffffff !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15) !important;
  border-radius: 3px !important;
}
.pricing_white_card_shadoww {
  text-align: center;
  background: #ffffff !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15) !important;
  border-radius: 3px !important;
}

.Prcing_card_center {
  text-align: center;
  padding: 20px 15px !important;
}
.pricing_white_card_shadow h3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #292929;
}
.pricing_white_card_shadow .prcing_amout {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  color: #000000;
}
.pricing_white_card_shadow .pricing_year {
  color: #000000;
}
.pricing_white_card_shadow p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #141431;
  padding: 0px 20px;
}
.pricing_white_card_shadow button {
  background: #1f55e1;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: 4px 4px 32px -4px rgba(31, 85, 225, 0.45);
  border-radius: 46px;
  outline: none !important;
  text-decoration: none !important;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  flex-direction: row;
  align-items: flex-start;
  padding: 11px 24px;
  width: 50%;
  margin-top: 9%;
}
.pricing_white_card_shadow .pricing_hr {
  padding: 0px 35%;
}
.pricing_white_card_shadow .pricing_hr hr {
  height: 2px;
  color: #292929;
}
.pricing_blue_card_shadow {
  background: #1f55e1 !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15) !important;
  border-radius: 3px !important;
}
.pricing_blue_card_shadow h3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
}
.pricing_blue_card_shadow .prcing_amout {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  color: #ffffff;
}
.pricing_blue_card_shadow p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  padding: 0px 20px;
}
.pricing_blue_card_shadow .pricing_year {
  color: #ffffff;
}
.pricing_blue_card_shadow button {
  flex-direction: row;
  align-items: flex-start;
  padding: 11px 24px;
  background: #ffffff;
  border: 3px solid #1f55e1;
  box-sizing: border-box;
  border-radius: 46px;
  outline: none !important;
  text-decoration: none !important;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #1f55e1;
  width: 50%;
}
.pricing_blue_card_shadow .pricing_hr {
  padding: 0px 35%;
}
.pricing_blue_card_shadow .pricing_hr hr {
  height: 2px;
  color: #ffffff;
}
.pricing_blue_card_shadow .pricing_button_padding {
  padding-top: 22%;
}
.pricing_padding_both {
  padding: 0% 10px 0px 10px;
}
.prcing_valur_right_set {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}
.pricing_best_value {
  position: absolute;
  transform: matrix(0.7, 0.7, -0.73, 0.7, 0, 0);
  top: 22px;
  right: -30px;
  background: #c4c4c4;
}
.pricing_best_value span {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  padding: 0px 20px;
  color: #292929;
}
.feature_card_padding {
  padding: 20px;
}
.our_customer_card_padding {
  padding: 15px;
}
.our_customer_card_shadow {
  background: #ffffff;
  border: 1px solid rgba(176, 176, 176, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15);
  border-radius: 3px;
}
.our_customer_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #171742;
}
.ouser_customer_profile {
  padding-left: 15px;
}
.ouser_customer_profile h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #171742;
}
.ouser_customer_profile p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #171742;
}
.our_customer_imge_width {
  width: 70px;
  height: 70px;
}
.our_customer_indicator {
  background-color: #1f55e1 !important;
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.contactus_left_card {
  background: #ffffff;
  border: 1px solid rgba(176, 176, 176, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 128px rgba(17, 127, 189, 0.15);
  border-radius: 3px;
  padding: 10px 15px;
}
.contact_address_data h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #292929;
}
.contact_address_data p {
  font-family: Nunito;
  font-style: normal;
  margin-bottom: 0px !important;
  font-size: 18px;
  line-height: 25px;
  color: #292929;
}
.schedule_meeting_form {
  padding-top: 50px;
}
.blank_error {
  color: red;
}
.paraPaddingtop {
  padding: 20px 0px 0px 0px;
}
.closeIconRight {
  text-align: right;
  cursor: pointer;
}
.paddingTopNewFeature {
  padding-top: 20px;
}

.flexCardContent {
  display: flex;
}
.featuresMainDivPadding {
  padding: 30px 70px 0px 70px;
}
.cardApddingMargin {
  margin: -2% 10%;
  padding: 10px;
  /* height: 125px !important; */
}
.joinFontSize {
  font-size: 25px;
  border-bottom: 1px solid #1f55e1;
}
.signupButtonFont {
  font-size: 20px !important;
}

.checkImageWidth {
  width: 15px;
  margin-right: 10px;
  height: 15px;
  margin-top: 6px;
}
.featureCardPaddingBottom {
  padding-bottom: 30px;
}
.contentPaddingLeft {
  padding-left: 10px;
}
.cardPaddingmargin {
  margin: 10px !important;
  padding: 10px !important;
}
.flexSpaceBottom {
  display: flex;
  position: absolute;
  bottom: 4%;
}
.flexSpace {
  display: flex;
}

.cardBorderColor {
  padding: 10px !important;
  height: 326px;
  position: relative;
}
