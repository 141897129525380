@media only screen and (min-width: 320px) and (max-width: 480px) {
  .common_main_grid {
    width: 100%;
    display: block !important;
    box-sizing: border-box;
  }
  .common_div_padding {
    padding: 60px 45px !important;
  }
}
.common_main_grid {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.common_div_padding {
  padding: 60px 70px;
}
.common_textfield_width {
  width: 100% !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.common_button {
  font-size: 14px;
  padding: 10px 30px;
  letter-spacing: 1px;
  font-weight: 400;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none !important;
  outline: none !important;
  display: inline-block;
  background: #0070bb;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  /* box-shadow: 4px 4px 32px -4px rgba(31, 85, 225, 0.45); */
  border-radius: 46px;
}
.common_h2 {
  /* font-family: Nunito; */
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;

  color: #000000;
}
html {
  scroll-padding-top: 70px; /* height of sticky header */
}
.cursor{
  cursor: pointer;
}
.position_absolute{
  position: absolute;
  z-index: 99;
  margin-top: 100px;
}
.common_padding_margin_top{
  scroll-margin-top: 20px;
}