.blogsmainDiv {
  min-height: 90vh;
  padding-top: 90px;
  width: 100%;
  font-family: Nunito;
}
.blogPadding {
  padding: 25px;
}
.blogContentSet {
  padding-top: 10px;
}
.blogContentSet h4 {
  font-weight: bold;
}
.blogContentSet p {
  margin-bottom: 8px;
  color: #6f1de2;
}
.blogTitleFlex {
  display: flex;
  padding: 10px;
}
.blogstitleSpace {
  padding-left: 20px;
}
.blogstitleSpace .topic {
  color: #6f1de2;
  margin: 0px;
}
.blogstitleSpace .heading {
  font-size: 20px;
  line-height: 1.3;
  color: #000;
  font-weight: 600;
}
.blogsspaceBtewwen {
  display: flex;
  justify-content: space-between;
}
.Padding_both {
  padding: 20px 10%;
}
.detailHeadingCenter {
  text-align: center;
}
.detailHeadingCenter h1 {
  color: black;
  line-height: 1.3;
}
.blogDateFlex {
  display: flex;
  justify-content: center;
}
.blogDetailsPadding {
  padding-left: 10px;
  color: #9da6af;
}
.blogImagepadiing {
  padding-top: 20px;
}
.contentColorandCenter {
  padding-top: 20px;
}
.cardHeadingColor {
  color: #000;
  font-weight: 600;
}
.imageBorderBlogs {
  border-radius: 10px;
}
.blogRightGridImage {
  border-radius: 5px;
}
.blogCardflex {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  width: 100%;
}
.BlogCardBorder {
  width: 30%;
  margin: 1.6%;
  box-shadow: inset 0 -1px 0 #dee2e6;
  padding-bottom: 8px;
}
.blogArrayImageBorder {
  border-radius: 5px;
}
.BlogCardBorder p {
  color: #6f1de2;
  margin: 8px 0px;
}
.BlogCardBorder h2 {
  min-height: 96px;
  font-size: 20px;
  color: #000;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 8px;
}
.BlogCardBorder span {
  color: #464d53;
  font-weight: 500;
  font-size: 11px;
}
.BlogCardBorder .borderShadow {
  box-shadow: inset 0 -1px 0 #dee2e6;
}
.BlogCardBorder:hover {
  box-shadow: inset 0 -1px 0 #0070bb;
}
.BlogCardBorder:hover h2 {
  color: #0070bb;
}
